import * as Sentry from '@sentry/browser'

import { PortfolioDefinition } from '../modules/portfolio/types'
import { PortfolioApiFields } from '../types'

// Keep in sync with ContentType in https://github.com/finvizhq/charts/blob/master/app/utils/fetch_api.ts
// START
export enum ContentType {
  ApplicationJson = 'application/json',
  ApplicationJsonUTF = 'application/json; charset=utf-8', // WriteAsJsonAsync
  FormUrlEncoded = 'application/x-www-form-urlencoded',
  Html = 'text/html; charset=utf-8',
}

function parseResponseForContentType(response: string, contentType: ContentType) {
  switch (contentType) {
    case ContentType.ApplicationJson:
    case ContentType.ApplicationJsonUTF:
    case ContentType.FormUrlEncoded:
      return JSON.parse(response)
    default:
      return response
  }
}
// END

export class ThrowableError extends Error {
  status: number
  isServerError = false
  body: unknown
  extra: Record<string, any> = {}

  constructor(status: number, message = 'ThrowableError', body = '', extra: Record<string, any> = {}) {
    super(`${status} ${message}`)
    this.status = status
    this.isServerError = status >= 500
    this.body = body
    this.extra = extra
  }
}

export interface CancelablePromise<ResolveType> extends Promise<ResolveType> {
  aborted?: boolean
  cancel?: () => void
}

/**
 * General api fetch utility which returns a cancelable promise
 */
export function apiRequest<ResolveType>(
  url: string,
  { query, headers = {}, ...options }: RequestInit & { query?: Record<string, any> } = {},
  throwOnAllErrors = false
): CancelablePromise<ResolveType | undefined> {
  const controller = 'AbortController' in window ? new AbortController() : undefined
  const requestUrl = query ? `${url}?${new URLSearchParams(query)}` : url
  const contentType: ContentType = (headers as any)['Content-Type'] ?? ContentType.ApplicationJson

  const requestOptions = {
    ...options,
    headers: {
      'Content-Type': contentType,
      ...headers,
    },
  }
  const request = fetch(requestUrl, { ...requestOptions, signal: controller?.signal })

  const cancelableRequest: CancelablePromise<ResolveType> = request
    .then(async (response) => {
      const textResponse = await response.text()
      const responseContentType: ContentType = (response.headers.get('Content-Type') as any) ?? ContentType.Html

      try {
        const parsedResponse = parseResponseForContentType(textResponse, responseContentType)
        if (!response.ok || response.status >= 500) {
          throw new ThrowableError(response.status, response.statusText, parsedResponse, {
            rayId: response.headers.get('cf-ray'),
          })
        }

        return parsedResponse
      } catch (error: any) {
        if (error instanceof ThrowableError) {
          throw error
        }
        throw new ThrowableError(response.status, error.message, textResponse, {
          rayId: response.headers.get('cf-ray'),
        })
      }
    })
    .catch((error: ThrowableError) => {
      if (error.status >= 500) {
        Sentry.withScope((scope) => {
          scope.setContext('Cloudflare', error.extra)

          if (error.status === 500) {
            Sentry.captureException(error)
          } else {
            Sentry.captureMessage(`${error.status} ${error.message}`)
          }
        })
      }
      if (throwOnAllErrors) {
        throw error
      }
    })

  if (controller) {
    cancelableRequest.cancel = () => {
      controller.abort()
      cancelableRequest.aborted = true
    }
  }

  return cancelableRequest
}

export interface SearchApiResult {
  ticker: string
  company: string
  exchange: string
}

export function searchApiRequest(input = '') {
  return apiRequest<SearchApiResult[]>('/api/suggestions.ashx', { query: { input } }, true)
}

export function portfoliosRequest() {
  return apiRequest<{ portfolios: PortfolioDefinition[] }>('/api/portfolio_data.ashx', {
    query: {
      fields: [PortfolioApiFields.UserPortfolios],
    },
  })
}

export function lastCloseRequest(ticker: string) {
  return apiRequest<string>('/request_quote.ashx', {
    query: {
      t: ticker,
    },
  })
}

interface PortfolioLimitResult {
  transactions: number
  limitTransactions: number
}

export function portfolioLimitRequest(portfolioId: number) {
  return apiRequest<PortfolioLimitResult>('/api/portfolio_data.ashx', {
    query: {
      pid: portfolioId,
      fields: [PortfolioApiFields.TransactionLimit, PortfolioApiFields.NumberOfTransactions],
    },
  })
}

export function setCookieRequest(name: string, value?: unknown) {
  return apiRequest(
    '/api/set_cookie.ashx',
    {
      keepalive: true,
      query: {
        cookie: name,
        ...(value !== undefined && {
          value: value,
        }),
      },
    },
    true
  )
}
