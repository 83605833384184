import classnames from 'classnames'

import { PopoverPlacement } from '../popover'
import { TooltipColor } from './types'

type PositionMain = 'auto' | 'top' | 'bottom' | 'left' | 'right'

const transformMap: Record<PositionMain, string> = {
  auto: '',
  top: 'rotateZ(180deg)',
  right: 'rotateZ(-90deg)',
  bottom: 'rotateZ(360deg)',
  left: 'rotateZ(90deg)',
}

interface TooltipArrowProps {
  color?: keyof typeof TooltipColor | 'custom'
  placement?: PopoverPlacement
  className?: string
}

/**
 * Custom arrow for the dropdown
 */
export function TooltipArrow({ placement, color = 'none', className }: TooltipArrowProps) {
  const positionMain = (placement?.split('-')[0] as PositionMain) ?? 'auto'
  const arrowColor = color !== 'custom' ? TooltipColor[color] : null

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" style={{ transform: transformMap[positionMain] }}>
      <path
        className={classnames(className, {
          'fill-gray-700': arrowColor === TooltipColor.dark,
          'fill-gray-700 dark:fill-gray-300': arrowColor === TooltipColor.none,
          'fill-red-400': arrowColor === TooltipColor.red,
          'fill-amber-600': arrowColor === TooltipColor.orange,
          'fill-blue-400': arrowColor === TooltipColor.blue,
          'fill-emerald-500': arrowColor === TooltipColor.green,
        })}
        d="M7.14 5.86a2 2 0 0 0-3.28 0L.3 11h10.4L7.14 5.86Z"
      />
    </svg>
  )
}
