import classnames from 'classnames'
import { ForwardedRef, HTMLProps, PropsWithChildren, ReactNode, forwardRef, useMemo } from 'react'

import { ButtonPadding } from './button'
import { Icon, IconNameType, isValidIconName } from './icon'
import { Label } from './typography'

export enum InputTheme {
  none,
  light,
  dark,
  navigationBar,
}

export enum InputRounding {
  none = '',
  regular = 'rounded-md',
}

/**
 * Enum for input size. Only set size on larger viewports to avoid page zooming
 */
export enum InputSize {
  small = 'h-6 text-2xs', // 24px height
  regular = 'h-7 text-2xs', // 28px height
  medium = 'h-8 text-sm', // 32px height
  large = 'h-9 text-sm', // 36px height
}

function getInputTheme({
  theme,
  isError,
  hasFocusStyle,
  isSelected,
}: {
  theme: InputTheme
  isError: boolean
  hasFocusStyle: boolean
  isSelected: boolean
}) {
  switch (theme) {
    case InputTheme.none:
      return {}
    case InputTheme.dark:
      return {
        input: classnames('border bg-gray-800 text-gray-50 disabled:bg-gray-700 disabled:text-gray-400', {
          'border-gray-600 hover:border-gray-400 disabled:border-gray-700': !isError,
          'border-red-400': isError,
          'focus:border-blue-400 focus:hover:border-blue-400': !isError && hasFocusStyle,
        }),
        icon: classnames({
          'text-gray-500': !isError,
          'text-red-400': isError,
          'group-focus-within:text-blue-400': !isError && hasFocusStyle,
        }),
      }
    case InputTheme.navigationBar:
      return {
        input: classnames(
          'border bg-transparent disabled:border-gray-700 disabled:bg-gray-700 disabled:text-gray-400',
          {
            'border-gray-100 dark:border-gray-600 text-gray-750 dark:text-gray-50':
              !isError && !FinvizSettings.hasUserPremium,
            'border-gray-600 text-gray-50': !isError && FinvizSettings.hasUserPremium,
            'border-red-400': isError,
          }
        ),
        icon: classnames({
          'text-gray-500': !isError,
          'text-red-400': isError,
        }),
      }
    default:
      return {
        input: classnames(
          'border', // Base styles
          'disabled:bg-secondary disabled:text-gray-500', // Light
          'dark:disabled:bg-gray-700 dark:disabled:text-gray-400', // Dark
          {
            'bg-primary': !isSelected,
            'border-gray-200 dark:border-primary hover:border-gray-300 disabled:hover:border-gray-100 dark:hover:border-gray-400 dark:disabled:border-gray-700':
              !isError && !isSelected,
            'border-red-400': isError,
            'focus:border-blue-400 hover:focus:border-blue-400 dark:focus:border-blue-400 dark:hover:focus:border-blue-400':
              !isError && !isSelected && hasFocusStyle,
            'bg-blue-50 border-blue-400 dark:bg-blue-700': isSelected,
          }
        ),
        icon: classnames({
          'text-gray-300 dark:text-gray-500': !isError && !isSelected,
          'text-red-400': isError,
          'group-focus-within:text-blue-400': !isError && !isSelected && hasFocusStyle,
          'text-blue-400': isSelected,
        }),
      }
  }
}

type OmitNativeProps<FromType> = Omit<HTMLProps<FromType>, 'label' | 'size'>

export interface InputProps {
  /**
   * Label which is rendered above the input
   */
  label?: ReactNode

  /**
   * Optional label layout switch
   */
  isHorizontalLabel?: boolean

  /**
   * Optional label className override
   */
  labelClassName?: string

  /**
   * Theme for the input
   *
   * @default light
   */
  theme?: keyof typeof InputTheme

  /**
   * Input size. This setting is ignored on mobile to avoid zoom
   *
   * @default regular
   */
  size?: keyof typeof InputSize

  /**
   * Border radius of the input
   *
   * @default regular
   */
  rounding?: keyof typeof InputRounding

  /**
   * Hide value stepper for number inputs
   */
  showNumberStepper?: boolean

  /**
   * Element to display on left the side of the input or name of the icon
   */
  leftContent?: JSX.Element | IconNameType

  /**
   * Element to display on right the side of the input or name of the icon
   */
  rightContent?: JSX.Element | IconNameType

  /**
   * Additional class names for the input
   */
  inputClass?: string

  /**
   * Error label to display under the input
   */
  error?: ReactNode

  /**
   * Additional info to display under the input
   */
  caption?: ReactNode

  /**
   * Disables setting text size to 16px on mobile. Only use for pages which aren’t
   * responsive
   */
  isMobileStyleEnabled?: boolean

  /**
   * Toggle for component focus styles
   *
   * @default true
   */
  hasFocusStyle?: boolean

  /**
   * Toggle for selected styles
   *
   * @default true
   */
  isSelected?: boolean
}

export type InputHTMLProps = OmitNativeProps<HTMLInputElement>

export type InputComponentProps = InputProps & InputHTMLProps

function InputComponent(
  {
    label,
    isHorizontalLabel,
    labelClassName,
    size = 'regular',
    theme = 'light',
    rounding = 'regular',
    type,
    showNumberStepper = type === 'number',
    leftContent,
    rightContent,
    inputClass,
    error,
    caption,
    className,
    isMobileStyleEnabled = true,
    hasFocusStyle = true,
    isSelected = false,
    ...props
  }: InputComponentProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const inputTheme = useMemo(
    () => getInputTheme({ theme: InputTheme[theme], isError: !!error, hasFocusStyle, isSelected }),
    [theme, error, hasFocusStyle, isSelected]
  )
  const isLeftStringIcon = isValidIconName(leftContent)
  const isRightStringIcon = isValidIconName(rightContent)
  const inputSize = InputSize[size]

  const commonSideContentClassnames = useMemo(
    () => ({
      'flex absolute w-3 items-center justify-center': true,
      'text-gray-500 dark:text-gray-600': theme === 'light',
      'text-gray-600': theme === 'dark',
    }),
    [theme]
  )

  return (
    <div className="w-full">
      <Label title={label} isHorizontal={isHorizontalLabel} className={labelClassName}>
        <div className={classnames(className, 'group relative flex')}>
          {leftContent && (
            <span
              className={classnames('pointer-events-none top-0 h-full', commonSideContentClassnames, {
                'left-1.5': inputSize === InputSize.small,
                'left-2': inputSize === InputSize.regular,
                'left-2.5': inputSize === InputSize.medium,
                'left-3': inputSize === InputSize.large,
              })}
            >
              {isLeftStringIcon ? (
                <Icon name={leftContent as IconNameType} width={16} className={inputTheme.icon} />
              ) : (
                leftContent
              )}
            </span>
          )}
          <input
            {...props}
            ref={ref}
            type={type}
            className={classnames(
              inputTheme.input,
              inputSize,
              InputRounding[rounding],
              inputClass,
              'w-full grow select-text appearance-none outline-none',
              {
                'mobile:h-8 mobile:text-base': isMobileStyleEnabled,
                'hide-stepper': type === 'number' && showNumberStepper === false,

                [ButtonPadding.small]: inputSize === InputSize.small,
                [ButtonPadding.regular]: inputSize === InputSize.regular,
                [ButtonPadding.medium]: inputSize === InputSize.medium,
                [ButtonPadding.large]: inputSize === InputSize.large,
              },
              isLeftStringIcon && {
                'pl-6': inputSize === InputSize.small,
                'pl-6.5': inputSize === InputSize.regular,
                'pl-7.5': inputSize === InputSize.medium,
                'pl-8': inputSize === InputSize.large,
              },
              !showNumberStepper &&
                isRightStringIcon && {
                  'pr-6': inputSize === InputSize.small,
                  'pr-6.5': inputSize === InputSize.regular,
                  'pr-7.5': inputSize === InputSize.medium,
                  'pr-8': inputSize === InputSize.large,
                },
              showNumberStepper && {
                'pr-0.5': inputSize === InputSize.small,
                'pr-1': inputSize === InputSize.regular,
                'pr-1.5': inputSize === InputSize.medium,
                'pr-2': inputSize === InputSize.large,
              }
            )}
          />
          {showNumberStepper && (
            <div
              className={classnames(
                'pointer-events-none top-1/2 -mt-2.5 h-5 w-5 rounded bg-gray-50 dark:bg-gray-700 mobile:hidden touch-device:hidden',
                commonSideContentClassnames,
                {
                  'right-0.5': inputSize === InputSize.small,
                  'right-1': inputSize === InputSize.regular,
                  'right-1.5': inputSize === InputSize.medium,
                  'right-2': inputSize === InputSize.large,
                }
              )}
            >
              <Icon name="caretVertical" width={16} className="text-gray-300 dark:text-gray-500" />
            </div>
          )}
          {rightContent && !showNumberStepper && (
            <span
              className={classnames('right-0 top-0 h-full', commonSideContentClassnames, {
                'right-1.5': inputSize === InputSize.small,
                'right-2': inputSize === InputSize.regular,
                'right-2.5': inputSize === InputSize.medium,
                'right-3': inputSize === InputSize.large,
              })}
            >
              {isRightStringIcon ? (
                <Icon name={rightContent as IconNameType} width={16} className={inputTheme.icon} />
              ) : (
                rightContent
              )}
            </span>
          )}
        </div>
      </Label>
      {caption && typeof caption !== 'boolean' && (
        <InputCaption className="mt-1 text-gray-500 dark:text-gray-600">{caption}</InputCaption>
      )}
      {error && typeof error !== 'boolean' && <InputCaption className="mt-1 text-red-600">{error}</InputCaption>}
    </div>
  )
}

interface InputCaptionProps extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  size?: keyof typeof InputSize
}

function InputCaption({ size = 'regular', ...props }: PropsWithChildren<InputCaptionProps>) {
  return (
    <div
      {...props}
      className={classnames(props.className, {
        'text-2xs': ['small', 'regular'].includes(size),
        'text-sm': ['medium', 'large'].includes(size),
      })}
    />
  )
}

type TextareaProps = Omit<InputProps, 'leftContent' | 'size'> & OmitNativeProps<HTMLTextAreaElement>

function TextareaComponent(
  {
    label,
    theme = 'light',
    rounding = 'regular',
    inputClass,
    error,
    caption,
    className,
    hasFocusStyle = true,
    isSelected = false,
    ...props
  }: TextareaProps,
  ref: ForwardedRef<HTMLTextAreaElement>
) {
  const textareaTheme = useMemo(
    () => getInputTheme({ theme: InputTheme[theme], isError: !!error, hasFocusStyle, isSelected }),
    [theme, error, hasFocusStyle, isSelected]
  )

  return (
    <div>
      <Label title={label}>
        <div className={classnames(className, 'flex w-full')}>
          <textarea
            {...props}
            ref={ref}
            className={classnames(
              textareaTheme.input,
              inputClass,
              InputRounding[rounding],
              'grow select-text appearance-none px-2 py-1 text-2xs outline-none mobile:text-base'
            )}
          />
        </div>
      </Label>
      {caption && typeof caption !== 'boolean' && (
        <InputCaption className="mt-1 text-gray-500 dark:text-gray-600">{caption}</InputCaption>
      )}
      {error && typeof error !== 'boolean' && <InputCaption className="mt-1 text-red-600">{error}</InputCaption>}
    </div>
  )
}

export const Input = forwardRef(InputComponent)
export const Textarea = forwardRef(TextareaComponent)
